$ = jQuery;

var prefix = '-edu';
/*Functions*/

function bind_data_writer_btn() {
    var data_writer_btn = $('[data-writer-btn]');
    data_writer_btn.off('click.data_writer_btn');

    data_writer_btn.on("click.data_writer_btn", function(e) {
        var id = $(this).attr('data-writer-btn');
        window.location.href = redirectObject.domain+'/order';
    });
}

function ga_topWriters_hire_card_ga() {
    var data_writer_btn = $('[topWriters_hire_card_ga]');
    data_writer_btn.off('click.topWriters_hire_card_ga');

    data_writer_btn.on("click.topWriters_hire_card_ga", function(e) {
        ga('send','event','CTA','click','TopWriters_hire_card');
    });
}


function setWritersOnline(){
    var min=720;
    var max=728;
    var item = $('.first_screen .first_screen_info strong span#counter_display');
    item.text(Math.floor(Math.random() * (+max - +min)) + +min);
}

function writersOnline() {
    var item = $('.first_screen .first_screen_info strong span#counter_display');
    var data = {
        'action': 'writers_online',
        'count': item.text()
    };
    $.ajax({
        url: '/wp-admin/admin-ajax.php',
        data:data,
        type:'POST',
        success:function(data){
            if( data ) {
                item.text(data);
            }
        }
    });
}

/*Scroll top*/
function showScrollTop() {
    if ($(this).scrollTop() > 100) {
        $("#to_top").addClass('active');
    }
    else {
        $("#to_top").removeClass('active');
    }
}

$(document).ready(function () {
    "use strict";
    var siteBody = $("body");
    /*Init*/
    siteBody.addClass(currentBrowser()).addClass(detectmob());
    showScrollTop();
    $(window).scroll(function () {
        showScrollTop();
    });
    /*Events*/
    // $(window).on('load', function () {
    //
    // });
    // $(window).bind('resize', function () {
    //
    // });

    /*Scroll top Click*/
    $("#to_top").on("click","a", function (event) {
        event.preventDefault();
        var id  = $(this).attr('href'),
            top = $(id).offset().top;
        $('html, body').animate({scrollTop: top}, 1000);
    });
    /*Menu*/
    $("#menuOpen button.hamburger").on('click',function (e) {
        $('#mainMenu').toggleClass("opened");
        $(this).parent().toggleClass("opened");
        $(this).toggleClass('is-active');
    });
    $('#mainMenu li.menu-item-has-children').append('<span class="subMenuButton i-angle-down"></span>');
    $('.subMenuButton').on('click',function () {
        var t = $(this);
        if(t.hasClass('open')) {
            t.removeClass('open').prev().slideUp(300);
        } else {
            t.removeClass('open').prev().slideDown(300);
            t.addClass('open');
        }
    });
    //WPCF7
    $(this).on('click', '.wpcf7-not-valid-tip', function () {
        $(this).prev().trigger('focus');
        $(this).fadeOut(500, function () {
            $(this).remove();
        });
    });
    //Privacy
    if(!getCookie('privacy')){
        var privacy = $('#privacy');
        privacy.removeClass('hide');
        privacy.show();
        // $('body').css('padding-bottom',privacy.innerHeight());
        // $('#to_top a').css('bottom',privacy.innerHeight() + 15);
    }
    $('#privacy .privacy_close').on('click',function () {
        $('#privacy').addClass('hide');
        // $('body').css('padding-bottom',0);
        // $('#to_top a').css('bottom','15px');
        setCookie('privacy','true',1);
    });




    /* Accordeon */
    var acc_var = $('.faq'+prefix).find('.acc_title');
    acc_var.click(function() {

        if($(this).hasClass('opened')){
            acc_var.removeClass('opened');
            $(this).next().slideToggle(300).parent().removeClass('open');
            $('.faq'+prefix).find('.info').slideUp(300).parent().removeClass('open');
        }
        else {
            $('.faq'+prefix).find('.info').slideUp(300).parent().removeClass('open');
            $(this).next().slideToggle(300).parent().addClass('open');
            acc_var.removeClass('opened');
            $(this).addClass('opened');
        }
    });
    acc_var.eq(0).click();

    /*Buttons*/
    $('[data-order-btn]').on("click", function(e) {
        window.location.href = redirectObject.domain+'/order';
    });
    $('[data-login-btn]').on("click", function(e) {
        window.location.href = redirectObject.domain+'/?login-first=1';
    });
    bind_data_writer_btn();
    $('[data-pdf]').on('click',function () {
        var link = $(this).attr('data-pdf');
        window.open(link,'_blank');
    });
    $('[data-chat]').on("click", function(e) {
        $zopim.livechat.window.show();
    });


    /**
     * GA
     */
    $('[first_screen_ga]').on("click", function(e) {
        ga('send','event','CTA','click','firstscreen-form');
    });

    $('[landing_ga]').on("click", function(e) {
        ga('send','event','CTA','Hire','landing');
    });

    $('[services_block_ga]').on("click", function(e) {
        ga('send','event','CTA','click','Services_block');
    });

    $('[services_blockwithboy_ga]').on("click", function(e) {
        ga('send','event','CTA','click','Services_blockwithboy');
    });

    $('[respontTime_block_ga]').on("click", function(e) {
        ga('send','event','CTA','click','RespontTime_block');
    });

    $('[order_blockWithSamples_ga]').on("click", function(e) {
        ga('send','event','CTA','click','Order_BlockwithSamples');
    });

    $('[faq_block_ga]').on("click", function(e) {
        ga('send','event','CTA','click','Faq_block');
    });

    $('[beforefooter_form_ga]').on("click", function(e) {
        ga('send','event','CTA','click','beforefooter_form');
    });

    $('.submit_ga').on("click", function(e) {
        ga('send','event','CTA','click','Submit');
    });

    $('[testimonials_card_ga]').on("click", function(e) {
        ga('send','event','CTA','Hire','testimonials_card');
    });

    $('[whatareyouWaiting_ga]').on("click", function(e) {
        ga('send','event','CTA','click','WhatareyouWaiting');
    });

    ga_topWriters_hire_card_ga();

    $('[inSEOtext_ga]').on("click", function(e) {
        ga('send','event','CTA','click','inSEOtext');
    });

    $('[ordernow_footer_ga]').on("click", function(e) {
        ga('send','event','CTA','click','ordernow_footer');
    });




    /**
     * Footer form datepicker
     */
    $( "#datepicker" ).datepicker({
        minDate: 0,
        maxDate: 62,
        dateFormat: "yy-mm-dd"
    });

});

/**
 * Footer fort counter
 * @type {*|HTMLElement}
 */
var pagesInput = $('#word_count');
function countWords() {
    $('#word_count_result').html(pagesInput.val() * 275);
}

function pagesP() {
    pagesInput.val(+pagesInput.val() + 1);
    countWords();
}

function pagesM() {
    if(pagesInput.val() > 1) {
        pagesInput.val(pagesInput.val() - 1);
        countWords();
    }
}

function addClassSwiper() {
    var activeSlide = $('.swiper-slide-active');
    $('.swiper-slide').removeClass('design_visible');
    activeSlide.addClass('design_visible');
    activeSlide.next().addClass('design_visible');
    activeSlide.next().next().addClass('design_visible');
    activeSlide.next().next().next().addClass('design_visible');
    activeSlide.prev().addClass('design_visible');
    activeSlide.prev().prev().addClass('design_visible');
    activeSlide.prev().prev().prev().addClass('design_visible');
}

function addClassSwiperS() {
    var activeSlide = $('.swiper-slide-active');
    $('.swiper-slide').removeClass('design_visible');
    activeSlide.addClass('design_visible');
    activeSlide.next().addClass('design_visible');
    activeSlide.next().next().addClass('design_visible');
    activeSlide.next().next().next().addClass('design_visible');
    activeSlide.next().next().next().next().addClass('design_visible');
    activeSlide.prev().addClass('design_visible');
    activeSlide.prev().prev().addClass('design_visible');
    // activeSlide.prev().prev().prev().addClass('design_visible');
}


$(document).ready(function(){
    var swiper = new Swiper('.swiper-container-slider',{
        effect: 'coverflow',
        grabCursor: false,
        centeredSlides: true,
        slidesPerView: 'auto',
        loop: true,
        coverflowEffect: {
            rotate: 0,
            stretch: 500,
            depth: 100,
            modifier: 1,
            slideShadows : false,
        },
        parallax: true,
        speed: 2000,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        preventClicks: false,
        preventClicksPropagation:false,
        breakpoints: {
            768: {
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true,
                }
            },
            767: {
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true,
                }
            }
        },
        on: {
            init: function () {
                addClassSwiper();
            },
            slideChange: function () {
                addClassSwiperS();
            }
        }
    });


    var swiper2 = new Swiper('.swiper-container-customer',{
        effect: 'coverflow',
        grabCursor: false,
        centeredSlides: true,
        slidesPerView: 'auto',
        loop: true,
        coverflowEffect: {
            rotate: 0,
            stretch: 500,
            depth: 100,
            modifier: 1,
            slideShadows : false,
        },
        parallax: true,
        speed: 2000,
        autoplay: {
            delay: 4000,
            disableOnInteraction: false
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        preventClicks: false,
        preventClicksPropagation:false,
        breakpoints: {
            767: {
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true,
                }
            }
        },
        on: {
            init: function () {
                addClassSwiper();
            },
            slideChange: function () {
                addClassSwiperS();
            }
        }
    });

    var breakpoint = window.matchMedia( '(max-width:767px)' );
    var breakpoint2 = window.matchMedia( '(width:768px)' );
    var swiperStatus;
    function swiperStatusCheck() {
        if(breakpoint.matches === true) {
            swiperStatus = 1;
        } else {
            swiperStatus = 0;
        }
    }
    swiperStatusCheck();
    $(window).bind('resize', function () {
        if(breakpoint.matches === true && swiperStatus === 0){
            if($("div").is(".swiper-container-slider")) {
                swiper.slideNext(1, true);
                swiper.update();
            }
            if($("div").is(".swiper-container-customer")) {
                swiper2.slideNext(1, true);
                swiper2.update();
            }
        }
        if(breakpoint2.matches === true){
            if($("div").is(".swiper-container-slider")) {
                swiper.slideNext(1, true);
                swiper.update();
            }
            if($("div").is(".swiper-container-customer")) {
                swiper2.slideNext(1, true);
                swiper2.update();
            }
        }
        swiperStatusCheck();
    });

    $('.swiper-container-slider .swiper-button-next').click(function(event) {
        event.preventDefault();
        swiper.slideNext(2000, true);
        addClassSwiper();
    });

    $('.swiper-container-slider .swiper-button-prev').click(function(event) {
        event.preventDefault();
        swiper.slidePrev(2000, true);
        addClassSwiper();
    });

    $('.swiper-container-customer .swiper-button-next').click(function(event) {
        event.preventDefault();
        swiper2.slideNext(2000, true);
        addClassSwiper();
    });

    $('.swiper-container-customer .swiper-button-prev').click(function(event) {
        event.preventDefault();
        swiper2.slidePrev(2000, true);
        addClassSwiper();
    });

    $(".order_form").on("submit", function() {
        var form = $(this),
            email = form.find(".header-order-email").val(),
            paper = form.find(".paper_type").attr('data-select-id');
        var link = null != email && void 0 != email && "" != email ? redirectObject.domain + "/fast-signup?email="+ email +"&foc_o_paper_type=" + paper : redirectObject.domain + "/order?foc_o_paper_type=" + paper;
        form.attr('action',link);
    });

    $(".order_form_aside").on("submit", function() {
        var form = $(this),
            email = form.find(".header-order-email").val(),
            paper = form.find(".paper_type").attr('data-select-id');
        var link = null != email && void 0 != email && "" != email ? redirectObject.domain + "/fast-signup?email="+ email +"&foc_o_paper_type=" + paper : redirectObject.domain + "/order?foc_o_paper_type=" + paper;
        form.attr('action',link);
    });

    $(".order_form_content").on("submit", function() {
        var form = $(this),
            email = form.find(".header-order-email").val();
        var link = null != email && void 0 != email && "" != email ? redirectObject.domain + "/fast-signup?email="+ email : redirectObject.domain + "/order";
        form.attr('action',link);
    });

    $(".big_order_form").on("submit", function() {
        var form = $(this),
            email = form.find(".header-order-email").val(),
            paper = form.find(".paper_type").attr('data-select-id'),
            pages = form.find(".header-order-pages").val(),
            date = form.find(".header-order-date").val(),
            subject = form.find(".subject").val(),
            time = form.find(".header-order-time").val(),

            parameners = "foc_o_paper_type=" + paper + "&foc_o_pages=" + pages + "&foc_o_subject=" + subject + "&foc_o_deadline=" + date + '+' +time + '%3A00';
        var link = null != email && void 0 != email && "" != email ? redirectObject.domain + "/fast-order?foc_u_email="+ email + '&' + parameners : redirectObject.domain + "/order?" + parameners;
        form.attr('action',link);
    });
});

/**
 * Ajax for writers
 */
var writers_loadmore_btn = $('#true_loadmore');
writers_loadmore_btn.click(function(){
    $(this).text('Loading...');
    var data = {
        'action': 'loadmore',
        'query': true_posts,
        'page' : current_page
    };
    $.ajax({
        url:ajaxurl,
        data:data,
        type:'POST',
        success:function(data){
            if( data ) {
                writers_loadmore_btn.text('Show more writers').parent().before(data);
                current_page++;
                if (current_page === max_pages) writers_loadmore_btn.remove();

                // Hide 'Load more' if there are 20 or more writers
                if(writers_loadmore_btn.parent().parent().find('.writer_box').length >= 20) writers_loadmore_btn.parent().remove();

                // Achievements hover
                writer_bind_events();
                // Order click
                bind_data_writer_btn();
                // Order click GA
                ga_topWriters_hire_card_ga();
            } else {
                writers_loadmore_btn.remove();
            }
        }
    });
});


function writer_bind_events() {
    var writer_box_cup =  $('.writer_box_cup');

    writer_box_cup.off('mouseenter.writer_box_cup');
    writer_box_cup.off('mouseleave.writer_box_cup');

    writer_box_cup.on('mouseenter.writer_box_cup', function(){
        var tooltipe = $(this).find('.writer_box_award');
        tooltipe.addClass('active');
    });

    writer_box_cup.on('mouseleave.writer_box_cup', function(){
        var tooltipe = $(this).find('.writer_box_award');
        tooltipe.removeClass('active');
    });
}

writer_bind_events();

$(window).scroll(function() {    
    var scroll = $(window).scrollTop();    
    if (scroll > 1) {
        $(".header_bottom").addClass("fix");
    } else {
        $(".header_bottom").removeClass("fix");
    }
});
